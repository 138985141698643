const color = {
  hoverBGLightBlue: "#b6c7ea",
  switchBGGrey: "#BDBDBD",
  iconBlue: "#2147A4",
  textDarkGrey: "#515974",
  bgLightGrey: "#F9FAFB",
  tooptipIconGreen: "#2E7D32",
  alertLightOrange: "#5F2B01",
  circularColor: "rgba(0, 0, 0, 0.38)",
  eyeIconColor: "#535A6E",
  errorBGColor: "#FBEAEA",
  errorTextColor: "#D32F2F",
  eyeIconColor1: "#0000008F",
};
export default color;
