import color from "./CommonEnum1";
const newSecondaryBtnCommon = {
  margin: "0px ",
  textAlign: "right",
  padding: "6px 8px 6px 8px ",
  fontWeight: 600,
  lineHeight: "24px",
  fontFamily: "Source Sans Pro !important",
  minWidth: "34px",
};
const CommonStyleObject = (theme) => ({
  primaryBtn: {
    position: "relative",
    display: "inline-block",
    padding: "8px 22px",
    border: `1px solid ${theme.palette.border.primaryBorder}`,
    color: theme.palette.text.textPrimaryColor,
    fontSize: "15px !important",
    backgroundColor: theme.palette.primary.main,
    // transition: "background 1s, color 1s ease",
    borderRadius: "50px",
    lineHeight: "24px",
    height: "42px",
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    minWidth: "inherit",
    "&:hover": {
      // opacity: 0.4,
      backgroundColor: theme.palette.hoverPrimaryColor.main,
      color: theme.palette.text.hoverTextPrimaryColor,
      border: `1px solid ${theme.palette.border.hoverPrimaryBorder}`,
    },
    "&:disabled": {
      backgroundColor: `${theme.palette.background.disable}!important`,
      color: `${theme.palette.text.disabled}!important`,
      border: `1px ${theme.palette.background.disable}!important`,
    },
  },
  secondaryBtn: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50px",
    textAlign: "right",
    padding: "8px 22px",
    fontSize: "15px !important",
    lineHeight: "24px",
    // transition: "background 1s, color 1s ease",
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    "&:hover": {
      // opacity: 0.5,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.textPrimaryColor,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "&:disabled": {
      backgroundColor: `${theme.palette.background.disable}!important`,
      color: `${theme.palette.text.disabled}!important`,
      fontWeight: 500,
      border: `1px ${theme.palette.background.disable}!important`,
    },
  },
  DashboardNewSecondaryBtn: {
    color: theme.palette.primary.main,
    ...newSecondaryBtnCommon,
  },
  newSecondaryBtn: {
    color: theme.palette.primary.main,
    ...newSecondaryBtnCommon,
    backgroundColor: theme.palette.hoverThirdColor.main,
    "&:hover": {
      backgroundColor: theme.palette.hoverThirdColor.main,
    },
  },
  header: {
    fontSize: "20px !important",
    fontWeight: 600,
    letterSpacing: "0.15px",
    lineHeight: "32px",
    fontFamily: "Source Sans Pro !important",
    color: "#383D4C",
  },
  body: {
    fontSize: "16px !important",
    fontWeight: "400",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    fontFamily: "Source Sans Pro !important",
    color: theme.palette.text.textSecondaryColorNew,
  },
  buttonProgress: {
    color: theme.palette.text.disabled,
    position: "absolute",
    marginLeft: "-12px",
  },
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  switch_track: {
    backgroundColor: color.switchBGGrey,
  },
  switch_base: {
    color: color.switchBGGrey,
    // "&.Mui-disabled": {
    //   color: "#e886a9",
    // },
    "&.Mui-checked": {
      color: `${theme.palette.primary.main} !important`,
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  switch_primary: {
    "&.Mui-checked": {
      color: `${theme.palette.primary.main} !important`,
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  circularCss: { color: color.circularColor, mr: "3px" },
  truncationCss: { wordBreak: "break-word", whiteSpace: "normal" },
  marginForResponsive: {
    // display: "block",
    // justifyContent: "center",
    // width: "100%",
    // marginLeft: " auto",
    // marginRight: "auto",
    // [theme.breakpoints.down("md")]: { width: "55% !important" },
    // [theme.breakpoints.down("sm")]: { width: "90% !important" },
    minHeight: "718px",
    p: "0% 13.1%",
    [theme.breakpoints.up("xl")]: { padding: "0% 22%" },
    [theme.breakpoints.down("sm")]: {
      padding: "0% 4.26%",
      marginBottom: "15px",
    },
  },
  buttonProgress1: {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: { top: "40px", left: "45%" },
  },
  backbtn: {
    mt: "40px",
    mb: "24px",
    "& span": {
      fontWeight: 600,
      fontSize: "15px !important",
      lineHeight: "26px",
      pl: "8px",
      letterSpacing: "0.46px",
    },
    [theme.breakpoints.down("sm")]: {
      mt: "24px",
      mb: "8px",
    },
  },
  titleText: {
    width: "100%",
    color: theme.palette.text.textPrimaryMainColor,
    fontSize: "32px !important",
    lineHeight: "41.99px",
    letterSpacing: "0.25px",
    paddingTop: "40px",
    // pl:"8px",
    pb: "24px",
    [theme.breakpoints.down("sm")]: { fontSize: "24px !important" },
  },
  fontSize34: { fontSize: "34px !important" },
  btnSubmitCss: {
    display: "flex",
    justifyContent: "end",
    gap: "16px",
    mb: "40px",
  },
  btn: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& button": { p: "8px 35px" },
    },
  },
  btnSubmitCssLeft: {
    [theme.breakpoints.down("sm")]: { justifyContent: "left" },
  },
  paperRoot: {
    borderRadius: "8px",
    border: "1px solid #EAECF0",
    boxShadow:
      "0px 6px 38px -23px rgba(25, 42, 85, 0.30), 0px 6px 38px -31px rgba(25, 42, 85, 0.20), 0px 8px 15px -31px rgba(25, 42, 85, 0.30)",
    mb: "24px",
    maxWidth: "100%",
  },
  algoFieldPad1: {
    p: "24px 6px 0px 6px",
    [theme.breakpoints.down("sm")]: { p: "8px 0px 4px 0px" },
  },
  cardShadow: {
    "& .aTagFont": { fontSize: "13px !important" },
    borderRadius: "8px",
    border: "1px solid #F3F4F6",
    background: "#FFF",
    boxShadow:
      "0px 6px 38px -23px rgba(25, 42, 85, 0.30), 0px 6px 38px -31px rgba(25, 42, 85, 0.20), 0px 8px 15px -31px rgba(25, 42, 85, 0.30)",
  },
  newCard: {
    borderRadius: "8px",
    border: "1px solid #F3F4F6",
    background: "#FFF",
    boxShadow:
      "0px 6px 38px -23px rgba(25, 42, 85, 0.30), 0px 6px 38px -31px rgba(25, 42, 85, 0.20), 0px 8px 15px -31px rgba(25, 42, 85, 0.30)",
    marginRight: "24px",
    margin: "0px",
    height: "100%",
  },
  padZero: { p: "0px" },
  padAxies: { px: "0px" },
  typoBody1: {
    fontSize: "16px !important",
    LineHight: "24px",
    letterSpacing: "0.15px",
    color: theme.palette.text.textPrimaryMainColor,
  },
  name: {
    minWidth: "326px",
    [theme.breakpoints.down("sm")]: { minWidth: "inherit" },
  },
});
const GuestLoginSignUpPasswordCommonCss = (theme, width) => ({
  authImageSection: {
    height: "100vh",
    backgroundImage: `url( ${require(`../../assets/images/others/${
      theme.custom.DesktopBGImage || "DesktopBgDC.png"
    }`)})`,
    backgroundRepeat: "round",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& .logoImageDesktop": {
      position: "absolute",
    },
    [theme.breakpoints.down("md")]: {
      height: "320px",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url( ${require(`../../assets/images/others/${
        theme.custom.loginBgImage || "DCBg.png"
      }`)})`,
      display: "block",
      "& .logoImageDesktop": {
        display: "none !important",
      },
    },
  },
  navBar: {
    height: "64px",
    width: "100%",
    display: "none",
    borderBottom: "1px solid #eaecf01a",
    [theme.breakpoints.down("md")]: { display: "block" },
    "& img": {
      pl: "18px",
      pt: theme.custom.productTitle === "iSage" && "20px",
    },
  },
  test: {
    height: "100vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      top: "64px",
      px: "16px",
      pt: "24px",
    },
  },
  hippa: {
    marginTop: "auto",
    boxShadow: "4px 0px 10px 0px #0000000D",
    fontFamily: "Source Sans Pro",
    border: "1px solid #EAECF0",
    background:
      "linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #eaecf0, #eaecf0)",
    borderRadius: "4px",
    letterSpacing: "0.17px",
    lineHeight: "20.02px",
    p: "16px 24px 24px 24px",
    "& p": { m: "0px" },
    color: `${theme.palette.text.textSecondaryColorNew}`,
    textAlign: "center",
    [theme.breakpoints.down("md")]: { marginBottom: "54px" },
  },
});
export { CommonStyleObject, GuestLoginSignUpPasswordCommonCss };
