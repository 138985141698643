import { API_MOUNT_PATH, glucoseUomEnum } from "../constants";
import { Typography, MenuItem, Divider } from "@mui/material";
import axios from "axios";
const handleTermCondition = async (file) => {
  const hostURL = window.location.host.includes("localhost");
  let tcDoc = file || "/tnc";
  window.open(
    hostURL
      ? `http://localhost:4001${API_MOUNT_PATH}/providerauthservice/termsAndConditions${tcDoc}/3/1`
      : `${API_MOUNT_PATH}/providerauthservice/termsAndConditions${tcDoc}/3/1`
  );
};
//User Guide
const handleuserguide = (props, hostURL) => {
  if (
    props.width === "xs" ||
    (props.width === "sm" && props.i18n.language === "pt")
  ) {
    window.open(
      hostURL
        ? `http://localhost:4001${API_MOUNT_PATH}/providerauthservice/userGuide?platform_id=4&isStudy=${props.currentUser.userDetails.isStudyAccAvailableKey}`
        : `${API_MOUNT_PATH}/providerauthservice/userGuide?platform_id=4&isStudy=${props.currentUser.userDetails.isStudyAccAvailableKey}`
    );
  } else {
    window.open(
      hostURL
        ? `http://localhost:4001${API_MOUNT_PATH}/providerauthservice/userGuide?platform_id=3&userrole=3&isStudy=${props.currentUser.userDetails.isStudyAccAvailableKey}`
        : `${API_MOUNT_PATH}/providerauthservice/userGuide?platform_id=3&userrole=3&isStudy=${props.currentUser.userDetails.isStudyAccAvailableKey}`
    );
  }
};
const cellnumberUpdate = (country, countryCode, number) => {
  if (country === "CA") {
    return number;
  }
  return `+${countryCode}${number}`;
};
const nameFormat = (FN, LN, props) => {
  let Name = `${LN} ${FN}`;
  if (props.t("common.FIRST_NAME_IS_FIRST")) {
    Name = `${FN} ${LN}`;
  }
  return Name;
};
const nameFormatForHCP = (FirstName, LastName, props) => {
  let NameFormat = `${LastName} ${FirstName}`;
  if (props.t("common.FIRST_NAME_IS_FIRST_HCP")) {
    NameFormat = `${FirstName} ${LastName}`;
  }
  return NameFormat;
};
const nameFormatForPHCP = (FName, LName, props) => {
  let FullName = `${LName} ${FName}`;
  if (props.t("common.FIRST_NAME_IS_FIRST_PHCP")) {
    FullName = `${FName} ${LName}`;
  }
  return FullName;
};
const nameFormatForProfile = (props, firstname, lastname) => {
  let ProfileName = `${lastname.charAt(0).toUpperCase()}${firstname
    .charAt(0)
    .toUpperCase()}`;
  if (props.t("common.FIRST_NAME_IS_FIRST_PHCP")) {
    ProfileName = `${firstname.charAt(0).toUpperCase()}${lastname
      .charAt(0)
      .toUpperCase()}`;
  }
  return ProfileName;
};
const nameFormatForProfileForHCP = (props, fname, lname) => {
  let ProfileNameFormat = `${lname.charAt(0).toUpperCase()}${fname
    .charAt(0)
    .toUpperCase()}`;
  if (props.t("common.FIRST_NAME_IS_FIRST_HCP")) {
    ProfileNameFormat = `${fname.charAt(0).toUpperCase()}${lname
      .charAt(0)
      .toUpperCase()}`;
  }
  return ProfileNameFormat;
};
const logOut = () => {
  localStorage.removeItem("__warningPopShownForSession");
  localStorage.removeItem("getAccountId");
  localStorage.removeItem("UserAuth");
  localStorage.removeItem("UserObject");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("supportedInsulin");
  axios({
    method: "get",
    url: `${API_MOUNT_PATH}/providerauthservice/signout`,
    headers: { "x-access-token": `${localStorage.getItem("accessToken")}` },
  }).then((response) => {
    //defining signature of the component
  });
  // props.history('../../../')
};
const menuListUI = (classes, props) => {
  return (
    <>
      <MenuItem sx={{ display: "block", py: "4px" }}>
        <Typography variant="body1" sx={classes.menuListCss}>
          {props.currentUser.userDetails.role === "CoWorker"
            ? nameFormatForPHCP(
                props.currentUser.userDetails.firstname,
                props.currentUser.userDetails.lastname,
                props
              )
            : nameFormatForHCP(
                props.currentUser.userDetails.firstname,
                props.currentUser.userDetails.lastname,
                props
              )}
        </Typography>
        <Typography variant="body2" sx={classes.MenuEmailCSS}>
          {props.currentUser.userDetails.emailid}
        </Typography>
      </MenuItem>
      <Divider />
    </>
  );
};
const bloodGlucoseUnitGlobal = (props, bgUom) => {
  if (bgUom === glucoseUomEnum.mmol) {
    return props.t("common.COMMON__MMOL_L");
  } else if (bgUom === glucoseUomEnum.mgdl) {
    return props.t("common.COMMON__MG_DL");
  } else {
    return props.currentUser.userDetails.glucoseUnit;
  }
};

const bloodGlucoseUnitAvailGlobal = (bgUom, props) => {
  if (bgUom) {
    return bgUom === glucoseUomEnum.mmol;
  } else {
    return props.currentUser.userDetails.glucoseUom === glucoseUomEnum.mmol;
  }
};

export {
  handleTermCondition,
  handleuserguide,
  cellnumberUpdate,
  nameFormat,
  logOut,
  menuListUI,
  bloodGlucoseUnitAvailGlobal,
  bloodGlucoseUnitGlobal,
  nameFormatForProfile,
  nameFormatForProfileForHCP,
  nameFormatForHCP,
  nameFormatForPHCP,
};
